<template>
  <div>
    <v-app style="background-color: #f5faff">
      <v-app-bar app elevation="0" v-if="havefolder === true" color="primary">
        <v-toolbar-title class="pt-1">
          <img src="@/assets/logo_onebox-horizontal-white.png" width="95" height="40" alt="One Box" />
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            fab
            icon
            color="primary"
            @click="downloadall(mainfolder)"
            v-if="resolutionScreen < 500"
          >
            <v-icon large>mdi-folder-download</v-icon>
          </v-btn>
          <v-btn text @click="downloadall(mainfolder)" v-else dark>
            <span>
              <v-icon medium>mdi-folder-download</v-icon>
              &nbsp; &nbsp;{{ " "+ $t('currentRClick.downloadfile')}}
            </span>
          </v-btn>
        </v-toolbar-items>
      </v-app-bar>

      <v-content>
        <v-divider></v-divider>
        <v-layout row wrap fill-height align-center justify-center v-if="loading === true">
          <v-flex lg6 xs11>
            <div class="text-center">
              <v-progress-circular indeterminate color="primary" size="100"></v-progress-circular>
            </div>
          </v-flex>
        </v-layout>
        <v-layout row wrap fill-height align-center justify-center v-else-if="havefolder === false">
          <v-flex lg6 xs11>
            <div class="text-center">
              <v-card outlined>
                <br />
                <v-card-text>
                  <v-icon size="100" medium color="red">mdi-folder-remove</v-icon>
                </v-card-text>
                <v-card-text
                  style="font-size: 20px"
                >ไม่พบโฟลเดอร์นี้ในระบบ หรือ โฟลเดอร์นี้ถูกปิดการแชร์</v-card-text>
                <br />
                <v-divider></v-divider>
                <v-card-action>
                  <p class="pt-1">
                    Powered By &nbsp;
                    <img
                      @click.stop="gotohomepage()"
                      class="pt-2"
                      style="vertical-align: text-bottom;"
                      src="@/assets/logo_onebox-horizontal-png.png"
                      width="65px"
                      height="35px"
                      alt="One Box2"
                    />
                  </p>
                </v-card-action>
              </v-card>
            </div>
          </v-flex>
        </v-layout>

        <v-container v-else>
          <br />
          <v-layout row wrap justify-center align-center>
            <v-flex lg12 xs11>
              <v-card outlined>
                <v-overlay :value="processloader" absolute color="grey lighten-4" dark>
                  <v-progress-circular indeterminate size="64" color="blue"></v-progress-circular>
                  <br />
                  <br />
                  <span style="color: #2196F3">&nbsp; &nbsp; loading</span>
                </v-overlay>
                <v-toolbar dense class="elevation-0 mt-1 mb-1">
                  <v-avatar color="primary" size="35">
                    <v-icon dark size="20">folder</v-icon>
                  </v-avatar>
                  <v-toolbar-title>
                    <v-breadcrumbs :items="navigate" divider=">">
                      <template v-slot:item="props">
                        <v-breadcrumbs-item
                          style="cursor: pointer;"
                          @click="gotofolder(props.item.text[1])"
                        >
                          <span
                            style="color: #1565C0; font-size: 20px; font-weight: 700;"
                          >{{ props.item.text[0] }}</span>
                        </v-breadcrumbs-item>
                      </template>
                    </v-breadcrumbs>
                    <!-- <span style="color: #1565C0; font-weight: 600;">&nbsp;{{ foldername }}</span> -->
                  </v-toolbar-title>

                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-btn fab small text @click="backtopreventfolder()" v-if="navigate.length > 1">
                      <v-icon>keyboard_backspace</v-icon>
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>
                <v-divider></v-divider>
                <v-card-text v-if="loadingdata === true" class="text-center">
                  <v-progress-circular indeterminate color="primary" size="50"></v-progress-circular>
                </v-card-text>
                <v-card-text v-else id="thiscontainer_publicfolder">
                  <br />
                  <v-layout row wrap class="pt-0 pb-0">
                    <v-flex lg2 xs12 class="pl-5 pr-5">
                      <v-select
                        prepend-inner-icon="sort"
                        hide-details
                        outlined
                        dense
                        v-model="size"
                        :items="listsize"
                        class="text-center"
                      >
                        <template v-slot:item="{ item }">
                          <div class="text-center">
                            <span style="font-size: 17px;">{{ item }}</span>
                          </div>
                        </template>
                        <template v-slot:selection="{ item }">
                          <div class="text-center">
                            <span style="font-size: 17px;">{{ item }}</span>
                          </div>
                        </template>
                      </v-select>
                      <br />
                    </v-flex>
                    <v-flex xs12 lg10 class="text-right">
                      <v-chip class="ma-2" color="primary" text-color="white">
                        <v-icon left>folder</v-icon>

                        {{countfolder + ' ' + $t('folders')}}
                      </v-chip>
                      <v-chip class="ma-2" color="primary" text-color="white">
                        <v-icon left>mdi-file</v-icon>

                        {{countfile + ' ' + $t('files')}}
                      </v-chip>
                    </v-flex>
                  </v-layout>
                  <!-- <v-breadcrumbs :items="navigate"  divider=">">
                        <template v-slot:item="props">
                        <v-breadcrumbs-item
                        style="cursor: pointer;"
                            @click="gotofolder(props.item.text[1])"
                        >
                            {{ props.item.text[0] }}
                        </v-breadcrumbs-item>
                        </template>
                        </v-breadcrumbs>
                  <br>-->
                  <div v-if="resolutionScreen <= 500">
                    <v-list two-line class="mb-6 pb-6">
                      <v-list-item
                        v-for="item in paginatedData"
                        :key="item.title"
                        style="cursor: pointer;"
                        @dblclick="gotodirectory(item)"
                      >
                        <v-list-item-avatar>
                          <v-icon large :color="item.file_icon[1]" v-text="item.file_icon[0]"></v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title v-text="item.file_name"></v-list-item-title>
                          <v-list-item-subtitle v-text="calculatesize(item.file_size)"></v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-btn class="elevation-0" fab small @click="downloadfile(item)">
                            <v-icon>mdi-download</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                      <!-- 
                      <v-divider inset></v-divider>-->
                    </v-list>
                  </div>

                  <div v-else>
                    <v-data-table
                      v-model="selected"
                      :headers="headers"
                      :items="filedata"
                      :single-select="singleSelect"
                      item-key="file_id"
                      :show-select="false"
                      :no-data-text="$t('tablefile.empty')"
                      class="elevation-1"
                      :page.sync="page"
                      :items-per-page="size"
                      :hide-default-footer="true"
                      @page-count="pageCount = $event"
                    >
                      <!-- <template v-slot:top>
                          <v-switch v-model="singleSelect" label="Single select" class="pa-3"></v-switch>
                      </template>-->
                      <template v-slot:[`header.file_type`]="{ header }">
                        <span
                          style="color: black; font-weight: 600; font-size:15px"
                        >{{ header.text }}</span>
                      </template>
                      <template v-slot:[`header.file_name`]="{ header }">
                        <span
                          style="color: black; font-weight: 600; font-size:15px"
                        >{{ $t(header.text) }}</span>
                      </template>
                      <template v-slot:[`header.file_date`]="{ header }">
                        <span
                          style="color: black; font-weight: 600; font-size:15px"
                        >{{ $t(header.text) }}</span>
                      </template>
                      <template v-slot:[`header.file_size`]="{ header }">
                        <span
                          style="color: black; font-weight: 600; font-size:15px"
                        >{{ $t(header.text) }}</span>
                      </template>

                      <template v-slot:[`header.file_count_in`]="{ }">
                        <v-chip color="indigo" text-color="white">
                          <v-icon left>mdi-file-cloud</v-icon>

                          {{totalfiles()}}
                        </v-chip>
                      </template>
                      <template v-slot:[`header.file_status`]="{ header }">
                        <span
                          style="color: black; font-weight: 600; font-size:15px"
                        >{{ $t(header.text) }}</span>
                      </template>
                      <template v-slot:body="{ items }">
                        <tbody>
                          <tr
                            v-for="(item, index) in items"
                            :key="index"
                            @dblclick="gotodirectory(item)"
                            style="cursor: pointer;"
                          >
                            <!-- @click="gotodirectory(item)" -->
                            <td class="text-center">
                              <v-icon
                                large
                                :color="item.file_icon[1]"
                                style="font-weight: 100"
                              >{{ item.file_icon[0] }}</v-icon>
                            </td>
                            <td>{{ item.file_name}}</td>
                            <td>{{ setformatdate(item.file_date)}}</td>
                            <td class="text-center">{{ calculatesize(item.file_size) }}</td>
                            <td class="text-center">
                              <div v-if="item.file_type === 'folder'">
                                <!-- <span style="font-size: 15px; color: #1A237E"><v-icon size="20" class="mt-0 pt-0" color="indigo">folder</v-icon> 0 </span> -->

                                <v-tooltip top>
                                  <template v-slot:activator="{ on }">
                                    <span style="font-size: 15px; color: #1A237E" v-on="on">
                                      <v-icon
                                        size="20"
                                        class="mt-0 pt-0"
                                        color="indigo"
                                      >mdi-file-cloud</v-icon>
                                      {{ item.file_count_in}}
                                    </span>
                                  </template>
                                  <span>{{ item.file_count_in + ' ' + $t('files') }}</span>
                                </v-tooltip>
                              </div>
                            </td>
                            <td class="text-center">
                              <v-btn class="elevation-0" fab small @click="downloadfile(item)">
                                <v-icon>mdi-download</v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-data-table>
                  </div>
                  <br />
                  <v-layout row wrap justify-center>
                    <v-flex xs11 lg11>
                      <v-pagination v-model="page" :length="pageCount || 0" color="primary"></v-pagination>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-content>
      <previewfile
        :show="openpreviewfile"
        :filedata="currentfile"
        :userid="userid"
        @closepreviewfile="openpreviewfile = false"
        @openpreviewfile="openpreviewfile = true"
      ></previewfile>
    <!-- snackbardowload -->
    <div class="text-center ma-2">
      <v-snackbar v-model="snackbardowload" bottom color="white" :timeout="timeout">
        <v-list color="white" style="padding-top: 0px;">
          <v-toolbar color="primary" dense short flat fab>
            {{$t('myinboxPage.dowload.dowloading')}}
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="snackbardowload = false" fab x-small>
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-list-item dense style="height:40px; width:400px;">
            <v-list-item-content>
              <v-list-item-title>{{namedowload}}</v-list-item-title>
            </v-list-item-content>
            <v-progress-circular
              :rotate="90"
              :size="35"
              :width="3"
              :value="percentCompleted"
              color="teal"
              style="margin-left:25px"
            >&nbsp; {{ percentCompleted }}</v-progress-circular>
          </v-list-item>
        </v-list>
      </v-snackbar>
    </div>

     <!-- snackbardowloadfolder -->
    <div class="text-center ma-2">
      <v-snackbar v-model="snackbardowloadfolder" bottom color="white" :timeout="timeout">
        <v-list color="white" style="padding-top: 0px;">
          <v-toolbar color="primary" dense short flat fab>
            {{$t('myinboxPage.dowload.dowloading')}}
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="snackbardowloadfolder = false" fab x-small>
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-list-item dense style="height:40px; width:400px;">
            <v-list-item-content>
              <v-list-item-title>{{namedowloadfolder}}</v-list-item-title>
            </v-list-item-content>
            <v-progress-circular
              :rotate="90"
              :size="35"
              :width="3"
              :value="percentCompleted"
              color="teal"
              style="margin-left:25px"
            >&nbsp; {{ percentCompleted }}</v-progress-circular>
          </v-list-item>
        </v-list>
      </v-snackbar>
    </div>
    <dialogsnackbermultidowload
      :show="opensnackbarmultidownload"
      @closedialog="opensnackbarmultidownload=false"
      :percent="percent"
      :namedowload="namedowload"
      :btsdownload="btsdownload"
    ></dialogsnackbermultidowload>
    <dialogsnackbardowloadfile
      :show="opensnackbardownloadfile"
      @closedialog="opensnackbardownloadfile = false"
      :filedata="file"
      :percen="newpercen"
      :name="namefile"
      :list="new_list"
      :btsdownload="btsdownload"
    ></dialogsnackbardowloadfile>
    </v-app>
  </div>
</template>

<script>
import download from "downloadjs";
import { mapState, mapGetters } from "vuex";
import { format } from "date-fns";
import { saveAs } from "file-saver";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import gbfdecodearraybuffer from "@/globalFunctions/decodearraybuffer";
const dialogsnackbermultidowload = () => import("../components/optional/dialog-snackbarmultidowmload");
const dialogsnackbardowloadfile = () => import("../components/optional/dialog-snackbardownloadfile");
const previewfile = () =>
  import("../components/optional/dialog-preview-public");

// const Toast = Swal.mixin({
//   toast: true,
//   position: "top-end",
//   showConfirmButton: false,
//   timer: 3000
// });
export default {
  components: { previewfile,dialogsnackbermultidowload ,dialogsnackbardowloadfile},
  data: function() {
    return {
      opensnackbardownloadfile:false,
      btsdownload:false,
      percent:0,
      opensnackbarmultidownload:false,
      namedowloadfolder:'',
      namedowload:'',
      timeout: 60000,
      percentCompleted: 0,
      snackbardowload:false,
      snackbardowloadfolder:false,
      page: 1,
      size: 50,
      listsize: [20, 50, 100],
      openpreviewfile: false,
      currentfile: {},
      navigate: [],
      mainfolder: "",
      accountid: "",
      userid: "",
      foldername: "",
      loading: true,
      havefolder: false,
      filedata: [],
      loadingdata: false,
      singleSelect: false,
      selected: [],
      headers: [
        {
          text: "#",
          align: "left",
          sortable: false,
          value: "file_type",
          width: "7%"
        },
        {
          text: "tablefile.filename",
          align: "left",
          value: "file_name",
          width: "35%",
          sortable: true
        },
        {
          text: "tablefile.date",
          value: "file_date",
          width: "15%",
          align: "left",
          sortable: true
        },
        {
          text: "tablefile.filesize",
          value: "file_size",
          width: "23%",
          align: "left",
          sortable: true
        },
        {
          text: "",
          value: "file_count_in",
          width: "11%",
          align: "left",
          sortable: false
        },
        {
          text: "tablefile.download",
          value: "file_status",
          width: "11%",
          align: "left",
          sortable: false
        }
      ]
    };
  },
  computed: {
    ...mapState({ processloader: "loading" }),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    countfolder: {
      get() {
        return this.filedata.filter(a => a.file_type === "folder").length || 0;
      }
    },
    countfile: {
      get() {
        return this.filedata.filter(a => a.file_type !== "folder").length || 0;
      }
    },
    pageCount: {
      get() {
        let l = this.filedata.length;
        let s = this.size;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      }
    },
    paginatedData() {
      const start = (this.page - 1) * this.size;
      const end = start + this.size;
      return this.filedata.slice(start, start + this.size);
    }
  },
  methods: {
    totalfiles() {
      let i;
      let total = 0;
      for (i = 0; i < this.filedata.length; i++) {
        total += this.filedata[i]["file_count_in"];
      }

      return total + this.countfile;
    },
    async getfiledetail() {
      let payload = {
        link: this.$route.query.id
      };
      let auth = await gbfGenerate.generateToken();
      var response = await this.axios.post(
        process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/get_share_folder_link",
        payload,
        {
          withCredentials: false,
          headers: { Authorization: auth.code },
        },
        

      );

      try {
        console.log(response.data);
        if (response.data.status === "OK") {
          this.loading = false;
          this.havefolder = true;
          this.loadingdata = true;
          this.mainfolder = response.data.result[0]["folder_id"];
          this.accountid = response.data.result[0]["account_id"];
          this.userid = response.data.result[0]["user_id"];
          this.loadfolder(this.mainfolder);
        } else {
          this.loading = false;
          this.havefile = false;
        }
      } catch (ex) {
        this.loading = false;
        this.havefile = false;
      }
    },
    async loadfolder(_folderid) {
      var payload = {
        user_id: this.userid,
        account_id: this.accountid,
        folder_id: _folderid,
        status: ""
      };
      let auth = await gbfGenerate.generateToken();
      var response = await this.axios.post(
        process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/search_folder_byid",
        payload,
        {
          headers: { Authorization: auth.code },
          withCredentials: false
        },
      );

      try {
        if (response.data.status === "OK") {         
          let checknavigate = this.navigate.findIndex(
            y => y.text[1] === _folderid
          );
          if (checknavigate === -1) {
            this.navigate.push({
              text: [
                response.data.your_folder[0]["folder_name"],
                response.data.your_folder[0]["folder_id"]
              ],
              disabled: false
            });
          } else {
            let datanavigate = this.navigate;
            let i;
            this.navigate = [];
            for (i = 0; i <= checknavigate; i++) {
              this.navigate.push(datanavigate[i]);
            }
          }
          this.foldername = response.data.your_folder[0]["folder_name"];

          var i;
          for (i = 0; i < response.data.folder.length; i++) {
            let datafolder = {};
            datafolder["file_id"] = response.data.folder[i]["folder_id"];
            datafolder["file_name"] = response.data.folder[i]["folder_name"];
            datafolder["file_size"] = "-";
            datafolder["file_date"] = "-";
            datafolder["file_linkshare"] = response.data.folder[i]["link"];
            datafolder["file_count_in"] =
              response.data.folder[i]["count_file_in"];
            datafolder["file_status_share"] =
              response.data.folder[i]["status_share"];
            datafolder["file_status_sharelink"] =
              response.data.folder[i]["status_share_link"];
            datafolder["file_status"] =
              response.data.folder[i]["folder_status"];
            datafolder["file_owner_eng"] = response.data.folder[i]["name_eng"];
            datafolder["file_owner_th"] = response.data.folder[i]["name_th"];
            datafolder["file_type"] = "folder";
            datafolder["file_icon"] = ["folder", "#FDD361"];
            this.filedata.push(datafolder);
          }
          this.loadfile(_folderid, "");
        } else {
          console.log(response.data);
        }
      } catch (ex) {
        console.log(ex);
      }
    },
    async loadfile(_folderid, _status) {
      var payload = {
        user_id: this.userid,
        account_id: this.accountid,
        folder_id: _folderid,
        status: status
      };
      let auth = await gbfGenerate.generateToken();
      var response = await this.axios.post(
        process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/search_file",
        payload,
        {
          headers: { Authorization: auth.code },
          withCredentials: false
        },
      );

      try {
        if (response.data.status === "OK") {
          this.loadingdata = false;
          var i;
          for (i = 0; i < response.data.data.length; i++) {
            let datafolder = {};
            let typefile = response.data.data[i]["filename"].split(".");
            datafolder["file_id"] = response.data.data[i]["id"];
            datafolder["file_name"] = response.data.data[i]["filename"];
            datafolder["file_size"] = response.data.data[i]["size_file"];
            datafolder["file_date"] = response.data.data[i]["last_dtm"];
            datafolder["file_status"] = response.data.data[i]["status_file"];
            datafolder["file_owner_eng"] = response.data.data[i]["name_eng"];
            datafolder["file_owner_th"] = response.data.data[i]["name_th"];
            datafolder["file_linkshare"] = response.data.data[i]["link"];
            datafolder["file_count_in"] = 0;
            datafolder["file_status_share"] =
              response.data.data[i]["status_share"];
            datafolder["file_status_sharelink"] =
              response.data.data[i]["status_share_link"];
            datafolder["file_type"] = typefile[typefile.length - 1];
            datafolder["file_icon"] = this.seticon(
              typefile[typefile.length - 1]
            );
            this.filedata.push(datafolder);
          }
          this.loadingdata = false;
        }
      } catch (ex) {
        console.log(ex);
      }
    },
    seticon(_extensionfile) {
      // console.log(_extensionfile);
      // let parameter_ = _extensionfile !== "" || _extensionfile !== undefined || _extensionfile !== null ? _extensionfile.toLowerCase() : _extensionfile
      let dataicon;
      if (_extensionfile === "xlsx" || _extensionfile === "xls") {
        dataicon = ["mdi-file-excel-outline", "green"];
      } else if (_extensionfile === "pptx" || _extensionfile === "ppt") {
        dataicon = ["mdi-file-powerpoint-outline", "orange"];
      } else if (_extensionfile === "docx" || _extensionfile === "doc") {
        dataicon = ["mdi-file-word-outline", "primary"];
      } else if (_extensionfile === "zip") {
        dataicon = ["mdi-zip-box-outline", "blue-grey"];
      } else if (_extensionfile === "pdf") {
        dataicon = ["mdi-file-pdf-outline", "red"];
      } else if (_extensionfile === "xml") {
        dataicon = ["mdi-file-code-outline", "orange"];
      } else if (_extensionfile === "txt" || _extensionfile === "txt") {
        dataicon = ["mdi-note-text-outline", "blue-grey"];
      } else if (
        _extensionfile === "jpeg" ||
        _extensionfile === "jpg" ||
        _extensionfile === "png"
      ) 
      {
        dataicon = ["mdi-file-image-outline", "blue-grey"];
      }else if(_extensionfile === "avi" ||
    _extensionfile === "mpeg" ||
    _extensionfile === "mov" ||
    _extensionfile === "mp4"||
    _extensionfile === "mkv" ||
    _extensionfile === "wmv" ||
    _extensionfile === "3gp" ||
    _extensionfile === "flv"){
      dataicon = ["mdi-file-video-outline", "purple"];
    }
       else {
        dataicon = ["mdi-file-outline", "black"];
      }

      return dataicon;
    },
    calculatesize(_sizefilebyte) {
      let size;
      if (_sizefilebyte >= 1099511992567 && _sizefilebyte <= 1000000000000000) {
        size = (_sizefilebyte / 1099511992567).toFixed(2) + " TB";
      } else if (_sizefilebyte >= 1073741824 && _sizefilebyte < 1099511992567) {
        size = (_sizefilebyte / 1073741824).toFixed(2) + " GB";
      } else if (_sizefilebyte >= 1048576 && _sizefilebyte < 1073741824) {
        size = (_sizefilebyte / 1048576).toFixed(2) + " MB";
      } else if (_sizefilebyte >= 1024 && _sizefilebyte < 1048576) {
        size = (_sizefilebyte / 1024).toFixed(2) + " KB";
      } else if (_sizefilebyte === "-") {
        size = _sizefilebyte;
      } else {
        size = _sizefilebyte + " B";
      }

      return size;
    },
    setformatdate(_datetime) {
      if (_datetime === "-") {
        return _datetime;
      } else {
        var year = _datetime.substring(0, 4);
        var month = _datetime.substring(4, 6);
        var day = _datetime.substring(6, 8);
        var time =
          _datetime.substring(8, 10) +
          ":" +
          _datetime.substring(10, 12) +
          ":" +
          _datetime.substring(12, 14);
        let alldate = year + "-" + month + "-" + day;
        return alldate + " " + time;
      }
    },
    gotofolder(_folderid) {
      this.loadingdata = true;
      this.filedata = [];
      this.loadfolder(_folderid);
    },
    gotodirectory(_folder) {
      if (_folder.file_type === "folder") {
        this.loadingdata = true;
        this.filedata = [];
        this.loadfolder(_folder.file_id);
      } else {
        this.currentfile = _folder;
        this.openpreviewfile = true;
      }
    },
    backtopreventfolder() {
      if (this.navigate.length === 1) {
        this.loadingdata = true;
        this.filedata = [];
        this.loadfolder(this.navigate[0]["text"][1]);
      } else {
        this.navigate.pop();
        this.loadingdata = true;
        this.filedata = [];
        this.loadfolder(this.navigate[this.navigate.length - 1]["text"][1]);
      }
    },
    fn_calculate_size(_sizefilebyte) {
      console.log("ขนาดไฟล์เป็น GB",(_sizefilebyte / 1073741824).toFixed(2));
       return parseFloat((_sizefilebyte / 1073741824).toFixed(2))
      // return gb_sizeDataConverter.convertStorageToKbMbGb(_sizefilebyte);
    },
    async downloadfile(_file) {
      let checkfilesize = this.fn_calculate_size(_file.size_file)
      this.btsdownload = true;
      this.percentCompleted = 0;
      if (_file.file_type === "folder" ){
        let url =
          process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER +
          "/api/v2/download_folder?account_id=" +
          this.accountid +
          "&folder_id=" +
          _file.file_id;
        // this.snackbardowload = true;
        this.opensnackbarmultidownload = true;
        this.namedowload = _file.file_name;
        let auth = await gbfGenerate.generateToken();
        this.axios
          .get(url, {
             headers: { Authorization: auth.code },
            onDownloadProgress: progressEvent => {
              // console.log(progressEvent);             
              let progresspercent = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              console.log(this.percentCompleted);
              // this.namedowload = _file.file_name;
              if (progresspercent === 95) {
                this.percentCompleted = 95;
              }else{
                this.percentCompleted = progresspercent
              }
              this.percent = this.percentCompleted
            },
            withCredentials: false,
            responseType: "arraybuffer"
          })
          .then(response => {
            if (response.statusText === "OK") {
              if (parseInt(response.headers["content-length"]) < 700 && parseInt(response.headers["content-type"]) === "application/json") {
                this.opensnackbarmultidownload = false;
                let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
                Toast.fire({ icon: "error", title: res_error.errorMessage });
                } else {
                  this.percentCompleted = 100;
                  this.btsdownload = false;
                  let headers = response.headers;
                  let blob = new Blob([response.data],{type:headers['content-type']});
                  let link = document.createElement("a");
                  link.href = window.URL.createObjectURL(blob);
                  link.download = _file.file_name;
                  link.click();

                  // const blob = new Blob([response.data]);
                  // const content = response.headers["content-type"];
                  // saveAs(blob, _file.file_name + ".zip");
                  setTimeout(() => {
                    this.opensnackbarmultidownload = false
                    }, 2500);
                }
            }
            
          })
          .catch(error => {
            this.btsdownload = false;
            this.opensnackbarmultidownload = false
            Toast.fire({
              icon: "error",
              title: "ไม่สามารถดาวน์โหลด " + _file.file_name + " นี้ได้"
            });
            console.log(error);
          });
      } else {
        let url =
          process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
          "/api/v2/download_file?user_id=" +
          this.userid +
          "&file_id=" +
          _file.file_id;
        // this.snackbardowload = true;
        if(checkfilesize < 1.00){

        this.opensnackbarmultidownload = true;
        this.namedowload = _file.file_name;
        this.axios
          .get(url, {
            onDownloadProgress: progressEvent => {
              let progresspercent = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              console.log(this.percentCompleted);
              this.namedowload = _file.file_name;
              if (progresspercent === 95) {
                this.percentCompleted = 95;
              }else{
                this.percentCompleted = progresspercent
              }
              this.percent = this.percentCompleted
            },
            withCredentials: false,
            responseType: "arraybuffer"
          })
          .then(response => {
            if (response.statusText === "OK") {
              this.percentCompleted = 100;
              this.btsdownload = false;
              setTimeout(() => {
                this.opensnackbarmultidownload = false
                }, 2500);
            }
            // console.log("fff",response);
            // this.namedowload = _file.file_name;
            const blob = new Blob([response.data]);
            const content = response.headers["content-type"];
            saveAs(blob, _file.file_name);
          })
          .catch(error => {
            this.btsdownload = false;
            this.opensnackbarmultidownload = false
            Toast.fire({
              icon: "error",
              title: "ไม่สามารถดาวน์โหลด " + _file.file_name + " นี้ได้"
            });
            console.log(error);
          });
        }else{
          this.opensnackbardownloadfile = true;
          this.btsdownload = true;
          let  timeout = (checkfilesize*4000)
          window.location.assign(url)
          setTimeout(() => {
            this.opensnackbardownloadfile = false;
          }, timeout)
        }
      }
    },
    // downloadfile(_file) {
    //   this.$store.dispatch("show_loading", true);
    //   if (_file.file_type === "folder") {
    //     let url =
    //       process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER +
    //       "/api/v2/download_folder?account_id=" +
    //       this.accountid +
    //       "&folder_id=" +
    //       _file.file_id;
    //     const link = document.createElement("a");
    //     link.href = url;
    //     // link.target= "_blank"
    //     link.download = _file.file_name + ".zip";
    //     document.body.appendChild(link);
    //     link.click();
    //     this.$store.dispatch("show_loading", false);
    //   } else {
    //     let url =
    //       process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
    //       "/api/v2/download_file?user_id=" +
    //       this.userid +
    //       "&file_id=" +
    //       _file.file_id;
    //     // window.location.assign(url);
    //     const link = document.createElement("a");
    //     link.href = url;
    //     // link.target= "_blank"
    //     link.download = _file.file_name; //or any other extension
    //     document.body.appendChild(link);
    //     link.click();
    //     setTimeout(() => {
    //       this.$store.dispatch("show_loading", false);
    //     }, 1000);
    //   }
    // },
    async downloadall(_folder) {
      this.btsdownload = true;
      // console.log("naviiiii",this.navigate[0].text[0]); 
      this.percentCompleted = 0;
        let url =
        process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER +
        "/api/v2/download_folder?account_id=" +
        this.accountid +
        "&folder_id=" +
        _folder;
        // this.snackbardowloadfolder = true;
        this.opensnackbarmultidownload = true;
        this.namedowload = this.navigate[0].text[0];
        let auth = await gbfGenerate.generateToken();
        this.axios
          .get(url, {
            headers: { Authorization: auth.code },
              onDownloadProgress: progressEvent => {
                // console.log(progressEvent);              
                let progresspercent = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                console.log(progresspercent);
                if (progresspercent === 95) {
                  this.percentCompleted = 95;
                }else{
                  this.percentCompleted = progresspercent;
                }
                this.percent = this.percentCompleted           
              },
              withCredentials: false,
              responseType: "arraybuffer"
            }
          )
          .then(response => {
            if (response.statusText === "OK") {
              if (parseInt(response.headers["content-length"]) < 700 && parseInt(response.headers["content-type"]) === "application/json") {
                this.opensnackbarmultidownload = false;
                let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
                Toast.fire({ icon: "error", title: res_error.errorMessage });
              } else {

                this.percentCompleted = 100;
                this.btsdownload = false;
                // const blob = new Blob([response.data]);
                // const content = response.headers["content-type"];
                // saveAs(blob, this.navigate[0].text[0] + ".zip");
                let headers = response.headers;
                  let blob = new Blob([response.data],{type:headers['content-type']});
                  let link = document.createElement("a");
                  link.href = window.URL.createObjectURL(blob);
                  link.download = this.navigate[0].text[0];
                  link.click();
                  setTimeout(() => {
                    this.opensnackbarmultidownload = false
                    }, 2500);
                }
            }
         
            this.clearmultipledownload();
            
          })
          .catch(error => {
            this.btsdownload = false;
            Toast.fire({
              icon: "error",
              title: "ไม่สามารถดาวน์โหลด " +  this.namedowload + " นี้ได้"
            });
            console.log(error);
          });
      
    },
    // downloadall(_folder) {
    //   console.log(_folder);   
    //   this.$store.dispatch("show_loading", true);
    //   let url =
    //     process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER +
    //     "/api/v2/download_folder?account_id=" +
    //     this.accountid +
    //     "&folder_id=" +
    //     _folder;
    //   // window.location.assign(url);
    //   const link = document.createElement("a");
    //   link.href = url;
    //   // link.target= "_blank"
    //   link.download = _folder.file_name + ".zip";
    //   document.body.appendChild(link);
    //   link.click();
    //   setTimeout(() => {
    //     this.$store.dispatch("show_loading", false);
    //   }, 1000);
    // }
  },
  mounted() {
    if (this.$route.query.id === "" || !this.$route.query.id) {
      this.loading = false;
    } else {
      this.getfiledetail();
      
    }
    sessionStorage.removeItem("onechat_token");
    sessionStorage.removeItem("onechat_plugin_enable");
    
  }
};
</script>

<style>
#thiscontainer_publicfolder {
  height: calc(90vh - 160px);
  overflow-y: scroll;
}
.v-snack__content {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}
</style>